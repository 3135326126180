import React from "react"
import styled from "styled-components"

//import Button from "../button/button"

const Wrapper = styled.div.attrs({
  className: "tileWrapper",
})`
  animation: fadeIn 0.5s;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(1%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  margin-bottom: 1.5rem;

  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-template-areas:
    "content";

  @media ${props => props.theme.mq.lg} {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-template-areas: "content";
  }

  .tileContent {
    padding: 0.5rem;
    @media ${props => props.theme.mq.sm} {
      padding: 1rem;
    }
    @media ${props => props.theme.mq.md} {
      padding: 1.5rem;
    }
  }
`
const TileContent = styled.div.attrs({
  className: "tileContent",
})`
  grid-area: content;
  position: relative;

  p {
    font-size: 0.75rem;
  }

  h3 {
    font-size: 1.4rem;
    margin-bottom: 0;
  }
`



const MapFeatureTile = React.memo(({ props }) => {

  return (
    <Wrapper>

      <TileContent>
        <h3>{props.title}</h3>

        <p>{props.subtitle}</p>

        <div dangerouslySetInnerHTML={{ __html: props.content }} />

        {/* {props.externalLink !== null &&
          <Button
            isLink={true}
            type="primary"
            url={props.externalLink}
            target="mapfeature"
          >
            Read more...
        </Button>} */}
      </TileContent>
    </Wrapper>
  )
})

export default MapFeatureTile
