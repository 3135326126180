// storedState, InitialState and currentState

 const initialFilterState = {
  where: undefined,
  who_adults: 2,
  who_children: 0,
  who_infants: 0,
  who_pets: 0,
  with_price: null,
  with_bedrooms: null,
  with_bathrooms: null,
  when_from: null,
  when_to: null,
  when_variance: 3,
  what: null,
}

const storedFilterState = () => {
  
    const storedState = typeof sessionStorage !== `undefined` && sessionStorage.filterState

    let storedFilter = storedState ? JSON.parse(storedState) : undefined

    if (storedFilter) {
      if (
        storedFilter.who_adults &&
        typeof storedFilter.who_adults !== "number"
      ) {
        storedFilter.who_adults = parseInt(storedFilter.who_adults)
      }
      if (
        storedFilter.who_children &&
        typeof storedFilter.who_children !== "number"
      ) {
        storedFilter.who_children = parseInt(storedFilter.who_children)
      }
      if (
        storedFilter.who_infants &&
        typeof storedFilter.who_infants !== "number"
      ) {
        storedFilter.who_infants = parseInt(storedFilter.who_infants)
      }
      if (
        storedFilter.who_pets &&
        typeof storedFilter.who_pets !== "number"
      ) {
        storedFilter.who_pets = parseInt(storedFilter.who_pets)
      }
      if (
        storedFilter.with_bathrooms &&
        typeof storedFilter.with_bathrooms !== "number"
      ) {
        storedFilter.with_bathrooms = parseInt(storedFilter.with_bathrooms)
      }
      if (
        storedFilter.with_bedrooms &&
        typeof storedFilter.with_bedrooms !== "number"
      ) {
        storedFilter.with_bedrooms = parseInt(storedFilter.with_bedrooms)
      }
      if (storedFilter.what && !Array.isArray(storedFilter.what)) {
        storedFilter.what = null
      }
      if (storedFilter.with_price) {
        if (!storedFilter.with_price.min) {
          storedFilter.with_price = null
        }
      }
      return storedFilter
    }
    return null;
}

const currentFilterState = () => {
  return{...initialFilterState, ...storedFilterState() }
}

const getFilterState = (filters, initialFilterState) => {
    if (filters) {
      return { ...initialFilterState, ...filters };
    } else {
      return initialFilterState;
    }
}

const filterHelpers = {
  currentFilterState,
  initialFilterState,
  storedFilterState,
  getFilterState
}

export default filterHelpers