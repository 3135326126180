import React, { useState } from 'react'
import { Marker, MarkerClusterer, InfoWindow, GoogleMap, LoadScript } from '@react-google-maps/api'
import PropertyTile from "../propertyTile/propertyTile"
import MapFeatureTile from "../googleMap/mapfeaturetile"
import MapFeatureButton from "../googleMap/mapfeaturebutton"
import { useInView } from 'react-intersection-observer'

import styled from "styled-components"


const MapWrapper = styled.div`

  height: 400px;

  @media ${props => props.theme.mq.sm} {
    height: 580px;
  }

  #property-map{
    height:100%;
    width: 100%;
  }
  
`


const markerIcon = (category) => {
  const iconSuffix = category && (category !== "Property") ? "-" + category.toLowerCase() : ""
  return {
    url: '/svgs/icons/map-marker' + iconSuffix + '.svg',
    //anchor: new google.maps.Point(25,50),
    //scaledSize: new google.maps.Size(50,50)
  }
}

const clusterOptions = {
  styles: [{
    url: '/svgs/icons/mapcluster3.svg',
    textColor: 'white',
    width: '66',
    height: '66',
  }],
};


const MapFeaturesWrapper = styled.div`
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 5px;

 // @media ${props => props.theme.mq.sm} {
   // justify-content: space-around;
 // }
  
`

const Map = ({ apikey, markers, zoom, showcats }) => {

  const [markerList, setMarkerList] = useState(markers)
  const [activeMarker, setActiveMarker] = useState(null)
  const [infoOpen, setInfoOpen] = useState(false)
  const [mapFeatures, setMapFeatures] = useState(showcats)
  
  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  const toggleInfoOpen = () => {
    setInfoOpen(!infoOpen)
  }

  const openInfoBox = marker => {
    //console.log('marker: ', marker.propRef)
    setActiveMarker(marker)
    toggleInfoOpen()
  }

  const featureClick = (category) => {
    const featcats = [...mapFeatures].map(feature => {
      if (feature.name === category.displayTitle) {
        feature.selected = !category.checked
        //Update markerList state
        const showMarkers = [...markerList].map(marker => {
          if (feature.name === marker.category) marker.selected = feature.selected
          return marker
        })
        setMarkerList(showMarkers)
      }
      return feature
    })
    setMapFeatures(featcats)

  }

  const renderFeatureButtons = catgList => {
    return catgList.map(category => (
      <MapFeatureButton
        key={category.name}
        isLink={false}
        onChildClick={featureClick}
        title={category.name}
        label={category.desc}
        displayValue={category.selected}
        reset={false}
      />
    ))
  }

  const renderMarkers = allMarkers => {
    return allMarkers !== null || allMarkers !== undefined
      ? <MarkerClusterer
        options={clusterOptions}
      >
        {
          (clusterer) => allMarkers.map((marker, i) => (
            <Marker
              key={marker.propRef}
              clusterer={clusterer}
              position={marker.latlng}
              onClick={() => { openInfoBox(marker) }}
              icon={markerIcon(marker.category)}
              visible={marker.category === "Property" ? true : marker.selected}
            />
          ))
        }
      </MarkerClusterer>
      : ""
  }

  return (
  <div ref={ref}>
    {inView ? (
      
    <LoadScript
      id="script-loader"
      googleMapsApiKey={apikey}
    >
      <MapWrapper>
        <GoogleMap
          id="property-map"
          zoom={zoom}
          center={markers[markers.length - 1].latlng}
        >
          {renderMarkers(markerList)}

          {activeMarker && infoOpen &&
            <InfoWindow position={activeMarker.latlng} onCloseClick={toggleInfoOpen}>
              {activeMarker.category === "Property" ?
                <PropertyTile props={activeMarker.info} key={activeMarker.info.propRef} />
                :
                <MapFeatureTile props={activeMarker.info} key={activeMarker.info.propRef} />
              }
            </InfoWindow>}
        </GoogleMap>
      </MapWrapper>
      {showcats &&
        <MapFeaturesWrapper>
          {renderFeatureButtons(mapFeatures)}
        </MapFeaturesWrapper>
      }
    </LoadScript> ) : null}
    </div>
  )

}

export default Map
