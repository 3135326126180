import React, { useState, useEffect } from "react"
import styled from "styled-components"
//import Button from "../button/button"
import Icon from "../../utils/icon"

const Wrapper = styled.div`
/* display: inline-block; */
vertical-align: middle;
/* margin-right: 20px; */
margin 5px 0;
cursor: pointer;
&.selected {
  opacity: 0.5;
}
.svgWrapper{
  padding: 0 5px;
  // top: -5px;
  // display: inline-block;
  // margin: 5px;
  // svg {
  //   position: relative;
  //   top: 5px;
  //   visibility: visible;
  // }
}
span {
  margin-right: 10px;
}
`

const MapFeatureButton = ({ title, label, onChildClick, reset, displayValue }) => {

  //State
  const [checkedState, setCheckedState] = useState({
    displayTitle: title,
    displayLabel: label,
    checked: displayValue,
  })

  let { checked } = checkedState

  //send up state
  function handleClick(event) {
    //console.log('checked:', event.currentTarget.checked)
    const checkBool = event.currentTarget.checked ? true : false

    setCheckedState(checkedState => ({
      ...checkedState,
      checked: !checkBool,
    }))

    onChildClick(checkedState)
  }

  useEffect(() => {
    if (reset === true) {
      setCheckedState(checkedState => ({
        ...checkedState,
        checked: false,
      }))
    }
  }, [checked, reset])

  return (
    <Wrapper className={checkedState.checked ? "selected" : ""} onClick={handleClick} checked={checkedState.checked}>
      <Icon className="mapmarker" name={"map-marker-" + checkedState.displayTitle.toLowerCase()} height="1.5em" />
      <span>
        {checkedState.displayLabel}
      </span>
    </Wrapper>
  )
}

export default MapFeatureButton
